var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _vm.listItem === true
                ? _c(
                    "v-list-item",
                    _vm._g(
                      {
                        on: {
                          click: function($event) {
                            return _vm.fetchColumnsForQuery(_vm.tableId)
                          }
                        }
                      },
                      on
                    ),
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("filter_alt")]
                          ),
                          _vm._v(" Query table ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    _vm._g(
                      {
                        class: [
                          "mr-1",
                          _vm.buttonName === "open querybuilder"
                            ? "caption font-weight-bold"
                            : ""
                        ],
                        attrs: {
                          small: _vm.smallActivationButton,
                          color: "primary",
                          text: "",
                          outlined: _vm.outlinedActivationButton
                        },
                        on: {
                          click: function($event) {
                            return _vm.fetchColumnsForQuery(_vm.tableId)
                          }
                        }
                      },
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("filter_alt")
                      ]),
                      _vm._v(" " + _vm._s(_vm.buttonName) + " ")
                    ],
                    1
                  )
            ]
          }
        }
      ]),
      model: {
        value: _vm.queryDialog,
        callback: function($$v) {
          _vm.queryDialog = $$v
        },
        expression: "queryDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "title primary--text",
              attrs: { "primary-title": "" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-space-between align-center",
                  staticStyle: { width: "100%" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v("filter_alt")
                      ]),
                      _c("span", { staticClass: "text-uppercase" }, [
                        _vm._v("Table query")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.queryDialog = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "mt-1" },
            [
              _c(
                "v-stepper",
                {
                  attrs: { vertical: "", color: "secondary" },
                  model: {
                    value: _vm.step,
                    callback: function($$v) {
                      _vm.step = $$v
                    },
                    expression: "step"
                  }
                },
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        color: "secondary",
                        complete: _vm.step > 1,
                        step: "1"
                      }
                    },
                    [
                      _vm._v("Select "),
                      _vm.step === 1
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              "Select one or more columns from the selected table"
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "1" } },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "1" } },
                                [
                                  _c("v-subheader", {
                                    domProps: { textContent: _vm._s("From") }
                                  })
                                ],
                                1
                              ),
                              _c("v-col", { attrs: { cols: "12", sm: "9" } }, [
                                _c("span", { staticClass: "subtitle-1" }, [
                                  _vm._v(_vm._s(_vm.selectedTable))
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "1" } },
                                [
                                  _c("v-subheader", {
                                    domProps: { textContent: _vm._s("Select") }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "9" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.columnData,
                                      label: "Columns",
                                      disabled:
                                        _vm.selectAllColumns ||
                                        _vm.fetchingTableColumnsForQuery,
                                      multiple: "",
                                      "item-text": "colName",
                                      "item-value": "colName",
                                      "persistent-hint": "",
                                      "single-line": "",
                                      loading: _vm.fetchingTableColumnsForQuery
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function(columnData) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center py-2"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "mr-2",
                                                      staticStyle: {
                                                        "min-width": "25px",
                                                        height: "25px"
                                                      }
                                                    },
                                                    [
                                                      _vm.selectedColumnsNames.includes(
                                                        columnData.item.colName
                                                      )
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "success"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-check-circle"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex align-center"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "subtitle-2 font-weight-bold primary--text mr-1"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  columnData
                                                                    .item
                                                                    .colName
                                                                ) + " "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-chip",
                                                            {
                                                              attrs: {
                                                                outlined: "",
                                                                "x-small": ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "overline"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      columnData
                                                                        .item
                                                                        .type
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "caption"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              columnData.item
                                                                .desc
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "prepend-item",
                                          fn: function() {
                                            return [
                                              _c("v-text-field", {
                                                staticClass: "px-4",
                                                attrs: {
                                                  autofocus: "",
                                                  label: "Filter"
                                                },
                                                model: {
                                                  value: _vm.columnSearch,
                                                  callback: function($$v) {
                                                    _vm.columnSearch = $$v
                                                  },
                                                  expression: "columnSearch"
                                                }
                                              })
                                            ]
                                          },
                                          proxy: true
                                        },
                                        _vm.selectAllColumns === true
                                          ? {
                                              key: "selection",
                                              fn: function(ref) {
                                                var item = ref.item
                                                var index = ref.index
                                                return [
                                                  index === 0
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.colName)
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  index === 1
                                                    ? _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            "(+" +
                                                              _vm._s(
                                                                _vm
                                                                  .selectedColumnsNames
                                                                  .length - 1
                                                              ) +
                                                              " others)"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          : null
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.selectedColumns,
                                      callback: function($$v) {
                                        _vm.selectedColumns = $$v
                                      },
                                      expression: "selectedColumns"
                                    }
                                  }),
                                  _c("v-checkbox", {
                                    staticClass: "mt-3 mx-0 pa-0",
                                    attrs: { label: "Select all columns" },
                                    model: {
                                      value: _vm.selectAllColumns,
                                      callback: function($$v) {
                                        _vm.selectAllColumns = $$v
                                      },
                                      expression: "selectAllColumns"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled:
                              !_vm.selectedTable ||
                              !_vm.selectedColumnsNames.length,
                            color: "secondary"
                          },
                          on: {
                            click: function($event) {
                              _vm.step = 2
                            }
                          }
                        },
                        [_vm._v("Continue")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        color: "secondary",
                        complete: _vm.step > 2,
                        step: "2"
                      }
                    },
                    [
                      _vm._v("Filter "),
                      _vm.step === 2
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              "Filter the selected columns for rows that meet certain criteria"
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "2" } },
                    [
                      _c(
                        "div",
                        { staticClass: "mb-12" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-3 font-weight-bold",
                              attrs: { text: "", color: "primary" },
                              on: { click: _vm.addFilter }
                            },
                            [_vm._v("+ add filter")]
                          ),
                          _vm.addedFilters.length > 0
                            ? _c(
                                "div",
                                _vm._l(_vm.addedFilters, function(
                                  filter,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: filter.index,
                                      staticClass: "mb-2 pt-5"
                                    },
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          model: {
                                            value:
                                              _vm.addedFilters[index].valid,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.addedFilters[index],
                                                "valid",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "addedFilters[index].valid"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex align-center"
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-3",
                                                  attrs: {
                                                    small: "",
                                                    color: "error",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.removeFilter(
                                                        filter.index
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-delete-outline")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  attrs: {
                                                    align: "end",
                                                    justify: "start",
                                                    "flex-wrap": "",
                                                    "no-gutters": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: { cols: "2" }
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          required: "",
                                                          rules: [
                                                            _vm.rules.required
                                                          ],
                                                          dense: "",
                                                          items:
                                                            _vm.selectedColumnsNames,
                                                          label: "column"
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.clearFilterDataUponColumnChange(
                                                              index
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.addedFilters[
                                                              index
                                                            ].column,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.addedFilters[
                                                                index
                                                              ],
                                                              "column",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "addedFilters[index].column"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm.addedFilters[index].column
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "2" }
                                                        },
                                                        [
                                                          _c("v-select", {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              dense: "",
                                                              items: _vm.columnFilters(
                                                                filter.column
                                                              ),
                                                              label:
                                                                "condition",
                                                              required: "",
                                                              rules: [
                                                                _vm.rules
                                                                  .required
                                                              ]
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .addedFilters[
                                                                  index
                                                                ].filter,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .addedFilters[
                                                                    index
                                                                  ],
                                                                  "filter",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "addedFilters[index].filter"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.addedFilters[index]
                                                    .column &&
                                                  _vm.addedFilters[index]
                                                    .filter &&
                                                  [
                                                    _vm.operatorTypes
                                                      .NULL_OPERATOR,
                                                    _vm.operatorTypes
                                                      .NON_NULL_OPERATOR
                                                  ].includes(
                                                    _vm.addedFilters[index]
                                                      .filter
                                                  ) === false &&
                                                  (_vm.dateAndTimeColumns.includes(
                                                    _vm.colType(
                                                      _vm.addedFilters[index]
                                                        .column
                                                    )
                                                  ) &&
                                                    _vm.addedFilters[index]
                                                      .filter !==
                                                      _vm.operatorTypes
                                                        .IN_OPERATOR) ===
                                                    false &&
                                                  _vm.addedFilters[index]
                                                    .file === null
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { cols: "2" }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              rules:
                                                                _vm.numericColumns.includes(
                                                                  _vm.colType(
                                                                    _vm
                                                                      .addedFilters[
                                                                      index
                                                                    ].column
                                                                  )
                                                                ) &&
                                                                _vm
                                                                  .addedFilters[
                                                                  index
                                                                ].filter !==
                                                                  _vm
                                                                    .operatorTypes
                                                                    .IN_OPERATOR
                                                                  ? [
                                                                      _vm.rules
                                                                        .required,
                                                                      _vm.rules
                                                                        .mustBeFloat
                                                                    ]
                                                                  : [
                                                                      _vm.rules
                                                                        .required
                                                                    ],
                                                              required: "",
                                                              autocomplete:
                                                                "new-value",
                                                              label:
                                                                _vm
                                                                  .addedFilters[
                                                                  index
                                                                ].filter ==
                                                                _vm
                                                                  .operatorTypes
                                                                  .IN_OPERATOR
                                                                  ? "comma seperated values"
                                                                  : "value",
                                                              dense: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .addedFilters[
                                                                  index
                                                                ].inputField1,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .addedFilters[
                                                                    index
                                                                  ],
                                                                  "inputField1",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "addedFilters[index].inputField1"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.addedFilters[index]
                                                    .column &&
                                                  _vm.addedFilters[index]
                                                    .filter &&
                                                  _vm.addedFilters[index]
                                                    .filter ===
                                                    _vm.operatorTypes
                                                      .BETWEEN_OPERATOR &&
                                                  _vm.numericColumns.includes(
                                                    _vm.colType(
                                                      _vm.addedFilters[index]
                                                        .column
                                                    )
                                                  )
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "1" }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-center justify-center mx-1",
                                                              staticStyle: {
                                                                height: "48px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-uppercase"
                                                                },
                                                                [_vm._v("and")]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.addedFilters[index]
                                                    .column &&
                                                  _vm.addedFilters[index]
                                                    .filter &&
                                                  _vm.addedFilters[index]
                                                    .filter ===
                                                    _vm.operatorTypes
                                                      .BETWEEN_OPERATOR &&
                                                  _vm.numericColumns.includes(
                                                    _vm.colType(
                                                      _vm.addedFilters[index]
                                                        .column
                                                    )
                                                  )
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "2" }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              required: "",
                                                              rules:
                                                                _vm.numericColumns.includes(
                                                                  _vm.colType(
                                                                    _vm
                                                                      .addedFilters[
                                                                      index
                                                                    ].column
                                                                  )
                                                                ) &&
                                                                _vm
                                                                  .addedFilters[
                                                                  index
                                                                ].filter !==
                                                                  _vm
                                                                    .operatorTypes
                                                                    .IN_OPERATOR
                                                                  ? [
                                                                      _vm.rules
                                                                        .required,
                                                                      _vm.rules
                                                                        .mustBeFloat
                                                                    ]
                                                                  : [
                                                                      _vm.rules
                                                                        .required
                                                                    ],
                                                              label: "value",
                                                              dense: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .addedFilters[
                                                                  index
                                                                ].inputField2,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .addedFilters[
                                                                    index
                                                                  ],
                                                                  "inputField2",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "addedFilters[index].inputField2"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.addedFilters[index]
                                                    .column &&
                                                  _vm.addedFilters[index]
                                                    .filter &&
                                                  _vm.addedFilters[index]
                                                    .filter ==
                                                    _vm.operatorTypes
                                                      .IN_OPERATOR
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "1" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "div",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "d-flex align-center"
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-file-input",
                                                                              {
                                                                                attrs: {
                                                                                  dense:
                                                                                    "",
                                                                                  accept:
                                                                                    ".txt",
                                                                                  label:
                                                                                    "Upload file",
                                                                                  "hide-input":
                                                                                    _vm
                                                                                      .addedFilters[
                                                                                      index
                                                                                    ]
                                                                                      .file ===
                                                                                    null
                                                                                },
                                                                                on: {
                                                                                  change: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.uploadFile(
                                                                                      $event,
                                                                                      index
                                                                                    )
                                                                                  }
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    _vm
                                                                                      .addedFilters[
                                                                                      index
                                                                                    ]
                                                                                      .file,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .addedFilters[
                                                                                        index
                                                                                      ],
                                                                                      "file",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "addedFilters[index].file"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Upload a plain text file (.txt), having one unquoted value per line. "
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.addedFilters[index]
                                                    .column &&
                                                  _vm.addedFilters[index]
                                                    .filter &&
                                                  [
                                                    _vm.operatorTypes
                                                      .NULL_OPERATOR,
                                                    _vm.operatorTypes
                                                      .NON_NULL_OPERATOR,
                                                    _vm.operatorTypes
                                                      .IN_OPERATOR
                                                  ].includes(
                                                    _vm.addedFilters[index]
                                                      .filter
                                                  ) === false &&
                                                  _vm.dateColumns.includes(
                                                    _vm.colType(
                                                      _vm.addedFilters[index]
                                                        .column
                                                    )
                                                  )
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { cols: "1" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-menu",
                                                            {
                                                              attrs: {
                                                                "close-on-content-click": false,
                                                                transition:
                                                                  "scale-transition",
                                                                "offset-y": "",
                                                                "min-width":
                                                                  "290px"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-text-field",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                label:
                                                                                  _vm
                                                                                    .addedFilters[
                                                                                    index
                                                                                  ]
                                                                                    .filter ===
                                                                                  _vm
                                                                                    .operatorTypes
                                                                                    .BETWEEN_OPERATOR
                                                                                    ? "Start date"
                                                                                    : "date",
                                                                                readonly:
                                                                                  "",
                                                                                dense:
                                                                                  "",
                                                                                rules: [
                                                                                  _vm
                                                                                    .rules
                                                                                    .required
                                                                                ],
                                                                                required:
                                                                                  ""
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .addedFilters[
                                                                                    index
                                                                                  ]
                                                                                    .datePicker1,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .addedFilters[
                                                                                      index
                                                                                    ],
                                                                                    "datePicker1",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "addedFilters[index].datePicker1"
                                                                              }
                                                                            },
                                                                            on
                                                                          )
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _c(
                                                                "v-date-picker",
                                                                {
                                                                  attrs: {
                                                                    "no-title":
                                                                      "",
                                                                    scrollable:
                                                                      ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .addedFilters[
                                                                        index
                                                                      ]
                                                                        .datePicker1,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .addedFilters[
                                                                          index
                                                                        ],
                                                                        "datePicker1",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "addedFilters[index].datePicker1"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.addedFilters[index]
                                                    .column &&
                                                  _vm.addedFilters[index]
                                                    .filter &&
                                                  [
                                                    _vm.operatorTypes
                                                      .NULL_OPERATOR,
                                                    _vm.operatorTypes
                                                      .NON_NULL_OPERATOR,
                                                    _vm.operatorTypes
                                                      .IN_OPERATOR
                                                  ].includes(
                                                    _vm.addedFilters[index]
                                                      .filter
                                                  ) === false &&
                                                  _vm.timeColumns.includes(
                                                    _vm.colType(
                                                      _vm.addedFilters[index]
                                                        .column
                                                    )
                                                  )
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "1" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-menu",
                                                            {
                                                              attrs: {
                                                                "close-on-content-click": false,
                                                                transition:
                                                                  "scale-transition",
                                                                "offset-y": "",
                                                                "min-width":
                                                                  "290px"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-text-field",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                label:
                                                                                  _vm
                                                                                    .addedFilters[
                                                                                    index
                                                                                  ]
                                                                                    .filter ===
                                                                                  _vm
                                                                                    .operatorTypes
                                                                                    .BETWEEN_OPERATOR
                                                                                    ? "Start time"
                                                                                    : "time",
                                                                                readonly:
                                                                                  "",
                                                                                dense:
                                                                                  "",
                                                                                rules: [
                                                                                  _vm
                                                                                    .rules
                                                                                    .required
                                                                                ],
                                                                                required:
                                                                                  ""
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .addedFilters[
                                                                                    index
                                                                                  ]
                                                                                    .timePicker1,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .addedFilters[
                                                                                      index
                                                                                    ],
                                                                                    "timePicker1",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "addedFilters[index].timePicker1"
                                                                              }
                                                                            },
                                                                            on
                                                                          )
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _c(
                                                                "v-time-picker",
                                                                {
                                                                  attrs: {
                                                                    "use-seconds":
                                                                      "",
                                                                    format:
                                                                      "24hr",
                                                                    scrollable:
                                                                      ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .addedFilters[
                                                                        index
                                                                      ]
                                                                        .timePicker1,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .addedFilters[
                                                                          index
                                                                        ],
                                                                        "timePicker1",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "addedFilters[index].timePicker1"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.addedFilters[index]
                                                    .column &&
                                                  _vm.addedFilters[index]
                                                    .filter &&
                                                  _vm.dateAndTimeColumns.includes(
                                                    _vm.colType(
                                                      _vm.addedFilters[index]
                                                        .column
                                                    )
                                                  ) &&
                                                  _vm.addedFilters[index]
                                                    .filter ===
                                                    _vm.operatorTypes
                                                      .BETWEEN_OPERATOR
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "1" }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-center justify-center mx-1",
                                                              staticStyle: {
                                                                height: "48px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-uppercase"
                                                                },
                                                                [_vm._v("and")]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.addedFilters[index]
                                                    .column &&
                                                  _vm.addedFilters[index]
                                                    .filter &&
                                                  _vm.dateColumns.includes(
                                                    _vm.colType(
                                                      _vm.addedFilters[index]
                                                        .column
                                                    )
                                                  ) &&
                                                  _vm.addedFilters[index]
                                                    .filter ===
                                                    _vm.operatorTypes
                                                      .BETWEEN_OPERATOR
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "1" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-menu",
                                                            {
                                                              attrs: {
                                                                "close-on-content-click": false,
                                                                transition:
                                                                  "scale-transition",
                                                                "offset-y": "",
                                                                "min-width":
                                                                  "290px"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-text-field",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                label:
                                                                                  "End date",
                                                                                readonly:
                                                                                  "",
                                                                                dense:
                                                                                  "",
                                                                                rules: [
                                                                                  _vm
                                                                                    .rules
                                                                                    .required
                                                                                ],
                                                                                required:
                                                                                  ""
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .addedFilters[
                                                                                    index
                                                                                  ]
                                                                                    .datePicker2,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .addedFilters[
                                                                                      index
                                                                                    ],
                                                                                    "datePicker2",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "addedFilters[index].datePicker2"
                                                                              }
                                                                            },
                                                                            on
                                                                          )
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _c(
                                                                "v-date-picker",
                                                                {
                                                                  attrs: {
                                                                    "no-title":
                                                                      "",
                                                                    scrollable:
                                                                      ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .addedFilters[
                                                                        index
                                                                      ]
                                                                        .datePicker2,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .addedFilters[
                                                                          index
                                                                        ],
                                                                        "datePicker2",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "addedFilters[index].datePicker2"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.addedFilters[index]
                                                    .column &&
                                                  _vm.addedFilters[index]
                                                    .filter &&
                                                  _vm.timeColumns.includes(
                                                    _vm.colType(
                                                      _vm.addedFilters[index]
                                                        .column
                                                    )
                                                  ) &&
                                                  _vm.addedFilters[index]
                                                    .filter ===
                                                    _vm.operatorTypes
                                                      .BETWEEN_OPERATOR
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "ml-2",
                                                          attrs: { cols: "1" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-menu",
                                                            {
                                                              attrs: {
                                                                "close-on-content-click": false,
                                                                transition:
                                                                  "scale-transition",
                                                                "offset-y": "",
                                                                "min-width":
                                                                  "290px"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-text-field",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                label:
                                                                                  "End time",
                                                                                readonly:
                                                                                  "",
                                                                                dense:
                                                                                  "",
                                                                                rules: [
                                                                                  _vm
                                                                                    .rules
                                                                                    .required
                                                                                ],
                                                                                required:
                                                                                  ""
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .addedFilters[
                                                                                    index
                                                                                  ]
                                                                                    .timePicker2,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .addedFilters[
                                                                                      index
                                                                                    ],
                                                                                    "timePicker2",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "addedFilters[index].timePicker2"
                                                                              }
                                                                            },
                                                                            on
                                                                          )
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _c(
                                                                "v-time-picker",
                                                                {
                                                                  attrs: {
                                                                    "use-seconds":
                                                                      "",
                                                                    format:
                                                                      "24hr",
                                                                    scrollable:
                                                                      ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .addedFilters[
                                                                        index
                                                                      ]
                                                                        .timePicker2,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .addedFilters[
                                                                          index
                                                                        ],
                                                                        "timePicker2",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "addedFilters[index].timePicker2"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled:
                              _vm.addedFilters.length > 0 &&
                              !_vm.areFiltersValid,
                            color: "secondary"
                          },
                          on: {
                            click: function($event) {
                              _vm.step = 3
                            }
                          }
                        },
                        [_vm._v("Continue")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function($event) {
                              _vm.step = 1
                            }
                          }
                        },
                        [_vm._v("Back")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        color: "secondary",
                        complete: _vm.step > 3,
                        step: "3"
                      }
                    },
                    [
                      _vm._v("Aggregate"),
                      _vm.step === 3
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              "Aggregate values in selected columns, forming groups based on the values of other columns"
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "3" } },
                    [
                      _c(
                        "div",
                        { staticClass: "mb-12" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-3 font-weight-bold",
                              attrs: { text: "", color: "primary" },
                              on: { click: _vm.addAggregationCondition }
                            },
                            [_vm._v("+ Add Aggregation Function")]
                          ),
                          _vm.addedAggregationConditions.length
                            ? _c(
                                "div",
                                _vm._l(_vm.addedAggregationConditions, function(
                                  aggregator,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "d-flex align-center"
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "error", icon: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.removeAggregationCondition(
                                                aggregator.index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-delete-outline")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-form",
                                        {
                                          model: {
                                            value: aggregator.valid,
                                            callback: function($$v) {
                                              _vm.$set(aggregator, "valid", $$v)
                                            },
                                            expression: "aggregator.valid"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                align: "center",
                                                "no-gutters": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { cols: "3" }
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      label: "Function",
                                                      items:
                                                        _vm.filteredAggregationFunctions,
                                                      required: "",
                                                      rules: [
                                                        _vm.rules.required
                                                      ]
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        aggregator.aggregationColumns = _vm.getAggregationColumnNames(
                                                          aggregator.aggFunction
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        aggregator.aggFunction,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          aggregator,
                                                          "aggFunction",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "aggregator.aggFunction"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "4" } },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      label: "Column",
                                                      items:
                                                        aggregator.aggregationColumns,
                                                      required: "",
                                                      rules: [
                                                        _vm.rules.required
                                                      ]
                                                    },
                                                    model: {
                                                      value: aggregator.column,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          aggregator,
                                                          "column",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "aggregator.column"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "1" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-center text-uppercase"
                                                    },
                                                    [_vm._v(" as ")]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Optional alias..",
                                                      rules: [
                                                        _vm.rules
                                                          .aliasNameDifferentThanColumNames,
                                                        _vm.rules
                                                          .uniqueAggregationAliasName
                                                      ]
                                                    },
                                                    model: {
                                                      value: aggregator.alias,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          aggregator,
                                                          "alias",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "aggregator.alias"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-6" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "font-weight-bold",
                              attrs: {
                                disabled:
                                  !_vm.addedAggregationConditions.length ||
                                  !_vm.validAggregationConditions.length ||
                                  _vm.onlyAggregationWithoutGroupBy,
                                text: "",
                                color: "primary"
                              },
                              on: { click: _vm.addAggregationFilter }
                            },
                            [_vm._v("+ Add Aggregation Filter")]
                          ),
                          _vm._l(_vm.addedAggregationFilters, function(
                            aggregationFilter,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "d-flex align-center"
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "error", icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeAggregationFilter(
                                          aggregationFilter.index
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", [_vm._v("mdi-delete-outline")])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-form",
                                  {
                                    model: {
                                      value: aggregationFilter.valid,
                                      callback: function($$v) {
                                        _vm.$set(
                                          aggregationFilter,
                                          "valid",
                                          $$v
                                        )
                                      },
                                      expression: "aggregationFilter.valid"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          align: "center",
                                          "no-gutters": ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "4" } },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                label: "Column",
                                                items:
                                                  _vm.validAggregationConditions
                                              },
                                              on: {
                                                change: function($event) {
                                                  aggregationFilter.valueType = _vm.getAggregationFilterColumnType(
                                                    aggregationFilter.column
                                                  )
                                                }
                                              },
                                              model: {
                                                value: aggregationFilter.column,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    aggregationFilter,
                                                    "column",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "aggregationFilter.column"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "mx-2",
                                            attrs: { cols: "3" }
                                          },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                label: "Filter",
                                                items: Object.keys(
                                                  _vm.aggregationFilterOperators
                                                ),
                                                required: "",
                                                rules: [_vm.rules.required]
                                              },
                                              model: {
                                                value: aggregationFilter.filter,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    aggregationFilter,
                                                    "filter",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "aggregationFilter.filter"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "2" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                required: "",
                                                rules:
                                                  _vm.numericColumns.includes(
                                                    aggregationFilter.valueType
                                                  ) ||
                                                  /^(COUNT|AVG)\(.*/.test(
                                                    aggregationFilter.column
                                                  )
                                                    ? [
                                                        _vm.rules.required,
                                                        _vm.rules.mustBeFloat
                                                      ]
                                                    : [_vm.rules.required]
                                              },
                                              model: {
                                                value: aggregationFilter.value,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    aggregationFilter,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "aggregationFilter.value"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled:
                              (_vm.addedAggregationConditions.length > 0 &&
                                !_vm.areAggregationConditionsValid) ||
                              (_vm.addedAggregationFilters.length > 0 &&
                                !_vm.areAggregationFiltersValid),
                            color: "secondary"
                          },
                          on: {
                            click: function($event) {
                              _vm.step = 4
                            }
                          }
                        },
                        [_vm._v("Continue")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function($event) {
                              _vm.step = 2
                            }
                          }
                        },
                        [_vm._v("Back")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        color: "secondary",
                        complete: _vm.step > 4,
                        step: "4"
                      }
                    },
                    [
                      _vm._v("Sort "),
                      _vm.step === 4
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              "Sort data in either ascending or descending according to one or more columns"
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "4" } },
                    [
                      _c(
                        "div",
                        { staticClass: "mb-12" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-3 font-weight-bold",
                              attrs: {
                                disabled: _vm.onlyAggregationWithoutGroupBy,
                                text: "",
                                color: "primary"
                              },
                              on: { click: _vm.addOrderingCondition }
                            },
                            [_vm._v("+ Add Ordering Column")]
                          ),
                          _vm.addedOrderingConditions.length
                            ? _c(
                                "div",
                                _vm._l(_vm.addedOrderingConditions, function(
                                  order,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "d-flex align-center"
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "error", icon: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.removeOrderingCondition(
                                                order.index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-delete-outline")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { align: "center" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c(
                                                "v-form",
                                                {
                                                  model: {
                                                    value: order.valid,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        order,
                                                        "valid",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "order.valid"
                                                  }
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items:
                                                        _vm.orderByColumnNames,
                                                      label: "Columns",
                                                      required: "",
                                                      rules: [
                                                        _vm.rules.required
                                                      ]
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "prepend-item",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  staticClass:
                                                                    "px-4",
                                                                  attrs: {
                                                                    autofocus:
                                                                      "",
                                                                    label:
                                                                      "Filter"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.orderBySearch,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.orderBySearch = $$v
                                                                    },
                                                                    expression:
                                                                      "orderBySearch"
                                                                  }
                                                                }
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value: order.column,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          order,
                                                          "column",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "order.column"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center"
                                                },
                                                [
                                                  _c(
                                                    "v-radio-group",
                                                    {
                                                      attrs: { row: "" },
                                                      model: {
                                                        value: order.order,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            order,
                                                            "order",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "order.order"
                                                      }
                                                    },
                                                    [
                                                      _c("v-radio", {
                                                        attrs: {
                                                          label: "Ascending",
                                                          value: "ASC"
                                                        }
                                                      }),
                                                      _c("v-radio", {
                                                        attrs: {
                                                          label: "Descending",
                                                          value: "DESC"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled:
                              _vm.addedOrderingConditions.length > 0 &&
                              !_vm.areOrderingConditionsValid,
                            color: "secondary"
                          },
                          on: {
                            click: function($event) {
                              _vm.step = 5
                            }
                          }
                        },
                        [_vm._v("Continue")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function($event) {
                              _vm.step = 3
                            }
                          }
                        },
                        [_vm._v("Back")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        color: "secondary",
                        complete: _vm.step > 5,
                        step: "5"
                      }
                    },
                    [
                      _vm._v("Row limit"),
                      _vm.step === 5
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v("Specify the number of records to return")
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "5" } },
                    [
                      _c(
                        "v-form",
                        {
                          staticClass: "my-3",
                          model: {
                            value: _vm.validRowNumberForm,
                            callback: function($$v) {
                              _vm.validRowNumberForm = $$v
                            },
                            expression: "validRowNumberForm"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: _vm.onlyAggregationWithoutGroupBy,
                              outlined: "",
                              dense: "",
                              required: "",
                              rules: [
                                _vm.rules.required,
                                _vm.rules.maxAndIntQueryRows
                              ]
                            },
                            model: {
                              value: _vm.numberRows,
                              callback: function($$v) {
                                _vm.numberRows = $$v
                              },
                              expression: "numberRows"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.validRowNumberForm === false,
                            color: "secondary"
                          },
                          on: {
                            click: function($event) {
                              _vm.step = 6
                            }
                          }
                        },
                        [_vm._v("Continue")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function($event) {
                              _vm.step = 4
                            }
                          }
                        },
                        [_vm._v("Back")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-step",
                    { attrs: { color: "secondary", step: "6" } },
                    [
                      _vm._v("Final query "),
                      _vm.step === 6
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              "Review the final query before submitting it"
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "6" } },
                    [
                      _c("pre", {
                        staticClass: "pb-5 pt-2",
                        staticStyle: { "max-width": "700px" },
                        domProps: { textContent: _vm._s(_vm.query) }
                      }),
                      _c("QuerySubmit", {
                        attrs: {
                          mode: "PREVIEW",
                          buttonName: "submit preview query",
                          iconShaped: false,
                          forDialog: true,
                          query: _vm.query
                        },
                        on: {
                          success: function($event) {
                            return _vm.closeDialogAndChangeTab()
                          }
                        }
                      }),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function($event) {
                              _vm.step = 5
                            }
                          }
                        },
                        [_vm._v("Back")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }