<template>
    <v-dialog v-model="queryDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <template v-slot:activator="{ on }">
            <v-list-item v-if="listItem === true" v-on="on" @click="fetchColumnsForQuery(tableId)">
                <v-list-item-title>
                    <v-icon class="mr-1" small>filter_alt</v-icon>
                    Query table
                </v-list-item-title>
            </v-list-item>
            <v-btn
                v-else
                v-on="on"
                :class="['mr-1', buttonName === 'open querybuilder' ? 'caption font-weight-bold' : '']"
                :small="smallActivationButton"
                color="primary"
                text
                :outlined="outlinedActivationButton"
                @click="fetchColumnsForQuery(tableId)"
            >
                <v-icon small>filter_alt</v-icon>
                {{ buttonName }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="title primary--text" primary-title>
                <div style="width: 100%" class="d-flex justify-space-between align-center">
                    <div class="d-flex align-center">
                        <v-icon class="mr-1">filter_alt</v-icon>
                        <span class="text-uppercase">Table query</span>
                    </div>
                    <v-btn icon @click="queryDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-1">
                <v-stepper v-model="step" vertical color="secondary">
                    <v-stepper-step color="secondary" :complete="step > 1" step="1"
                        >Select
                        <small class="mt-1" v-if="step === 1">Select one or more columns from the selected table</small>
                    </v-stepper-step>
                    <v-stepper-content step="1">
                        <div>
                            <v-row align="center">
                                <v-col cols="12" sm="1">
                                    <v-subheader v-text="'From'"></v-subheader>
                                </v-col>
                                <v-col cols="12" sm="9">
                                    <span class="subtitle-1">{{ selectedTable }}</span>
                                </v-col>
                            </v-row>
                            <v-row align="center">
                                <v-col cols="12" sm="1">
                                    <v-subheader v-text="'Select'"></v-subheader>
                                </v-col>
                                <v-col cols="12" sm="9">
                                    <v-select
                                        v-model="selectedColumns"
                                        :items="columnData"
                                        label="Columns"
                                        :disabled="selectAllColumns || fetchingTableColumnsForQuery"
                                        multiple
                                        item-text="colName"
                                        item-value="colName"
                                        persistent-hint
                                        single-line
                                        :loading="fetchingTableColumnsForQuery"
                                    >
                                        <template v-slot:item="columnData">
                                            <div class="d-flex align-center py-2">
                                                <div class="mr-2" style="min-width: 25px; height: 25px">
                                                    <v-icon v-if="selectedColumnsNames.includes(columnData.item.colName)" color="success"
                                                        >mdi-check-circle</v-icon
                                                    >
                                                </div>
                                                <div class="d-flex flex-column">
                                                    <div class="d-flex align-center">
                                                        <span class="subtitle-2 font-weight-bold primary--text mr-1">{{ columnData.item.colName }} </span>
                                                        <v-chip outlined x-small
                                                            ><span class="overline">{{ columnData.item.type }}</span></v-chip
                                                        >
                                                    </div>

                                                    <span class="caption">{{ columnData.item.desc }}</span>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-slot:prepend-item>
                                            <v-text-field autofocus v-model="columnSearch" class="px-4" label="Filter"></v-text-field>
                                        </template>
                                        <template v-if="selectAllColumns === true" v-slot:selection="{ item, index }">
                                            <span v-if="index === 0">{{ item.colName }}</span>
                                            <span class="mr-2" v-if="index === 1">(+{{ selectedColumnsNames.length - 1 }} others)</span>
                                        </template>
                                    </v-select>
                                    <v-checkbox class="mt-3 mx-0 pa-0" v-model="selectAllColumns" label="Select all columns"></v-checkbox>
                                </v-col>
                            </v-row>
                        </div>
                        <v-btn :disabled="!selectedTable || !selectedColumnsNames.length" color="secondary" @click="step = 2">Continue</v-btn>
                    </v-stepper-content>
                    <v-stepper-step color="secondary" :complete="step > 2" step="2"
                        >Filter
                        <small class="mt-1" v-if="step === 2">Filter the selected columns for rows that meet certain criteria</small>
                    </v-stepper-step>
                    <v-stepper-content step="2">
                        <div class="mb-12">
                            <v-btn @click="addFilter" text class="mr-3 font-weight-bold" color="primary">+ add filter</v-btn>
                            <div v-if="addedFilters.length > 0">
                                <div v-for="(filter, index) in addedFilters" :key="filter.index" class="mb-2 pt-5">
                                    <v-form v-model="addedFilters[index].valid">
                                        <div class="d-flex align-center">
                                            <v-btn @click="removeFilter(filter.index)" small color="error" icon class="mr-3">
                                                <v-icon>mdi-delete-outline</v-icon>
                                            </v-btn>
                                            <v-row align="end" justify="start" flex-wrap no-gutters>
                                                <v-col class="mr-2" cols="2">
                                                    <v-select
                                                        @change="clearFilterDataUponColumnChange(index)"
                                                        required
                                                        :rules="[rules.required]"
                                                        dense
                                                        v-model="addedFilters[index].column"
                                                        :items="selectedColumnsNames"
                                                        label="column"
                                                    ></v-select>
                                                </v-col>
                                                <v-col v-if="addedFilters[index].column" cols="2">
                                                    <v-select
                                                        dense
                                                        :items="columnFilters(filter.column)"
                                                        v-model="addedFilters[index].filter"
                                                        class="mr-2"
                                                        label="condition"
                                                        required
                                                        :rules="[rules.required]"
                                                    ></v-select>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        addedFilters[index].column &&
                                                            addedFilters[index].filter &&
                                                            [operatorTypes.NULL_OPERATOR, operatorTypes.NON_NULL_OPERATOR].includes(
                                                                addedFilters[index].filter
                                                            ) === false &&
                                                            (dateAndTimeColumns.includes(colType(addedFilters[index].column)) &&
                                                                addedFilters[index].filter !== operatorTypes.IN_OPERATOR) === false &&
                                                            addedFilters[index].file === null
                                                    "
                                                    class="mr-2"
                                                    cols="2"
                                                >
                                                    <v-text-field
                                                        :rules="
                                                            numericColumns.includes(colType(addedFilters[index].column)) &&
                                                            addedFilters[index].filter !== operatorTypes.IN_OPERATOR
                                                                ? [rules.required, rules.mustBeFloat]
                                                                : [rules.required]
                                                        "
                                                        required
                                                        autocomplete="new-value"
                                                        :label="addedFilters[index].filter == operatorTypes.IN_OPERATOR ? 'comma seperated values' : 'value'"
                                                        v-model="addedFilters[index].inputField1"
                                                        dense
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        addedFilters[index].column &&
                                                            addedFilters[index].filter &&
                                                            addedFilters[index].filter === operatorTypes.BETWEEN_OPERATOR &&
                                                            numericColumns.includes(colType(addedFilters[index].column))
                                                    "
                                                    cols="1"
                                                >
                                                    <div style="height: 48px" class="d-flex align-center justify-center mx-1">
                                                        <span class="text-uppercase">and</span>
                                                    </div>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        addedFilters[index].column &&
                                                            addedFilters[index].filter &&
                                                            addedFilters[index].filter === operatorTypes.BETWEEN_OPERATOR &&
                                                            numericColumns.includes(colType(addedFilters[index].column))
                                                    "
                                                    cols="2"
                                                >
                                                    <v-text-field
                                                        required
                                                        :rules="
                                                            numericColumns.includes(colType(addedFilters[index].column)) &&
                                                            addedFilters[index].filter !== operatorTypes.IN_OPERATOR
                                                                ? [rules.required, rules.mustBeFloat]
                                                                : [rules.required]
                                                        "
                                                        label="value"
                                                        v-model="addedFilters[index].inputField2"
                                                        dense
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        addedFilters[index].column &&
                                                            addedFilters[index].filter &&
                                                            addedFilters[index].filter == operatorTypes.IN_OPERATOR
                                                    "
                                                    cols="1"
                                                >
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <div v-on="on" class="d-flex align-center">
                                                                <v-file-input
                                                                    dense
                                                                    v-model="addedFilters[index].file"
                                                                    @change="uploadFile($event, index)"
                                                                    accept=".txt"
                                                                    label="Upload file"
                                                                    :hide-input="addedFilters[index].file === null"
                                                                ></v-file-input>
                                                            </div>
                                                        </template>
                                                        <span>Upload a plain text file (.txt), having one unquoted value per line. </span>
                                                    </v-tooltip>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        addedFilters[index].column &&
                                                            addedFilters[index].filter &&
                                                            [operatorTypes.NULL_OPERATOR, operatorTypes.NON_NULL_OPERATOR, operatorTypes.IN_OPERATOR].includes(
                                                                addedFilters[index].filter
                                                            ) === false &&
                                                            dateColumns.includes(colType(addedFilters[index].column))
                                                    "
                                                    class="mr-2"
                                                    cols="1"
                                                >
                                                    <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                v-model="addedFilters[index].datePicker1"
                                                                :label="addedFilters[index].filter === operatorTypes.BETWEEN_OPERATOR ? 'Start date' : 'date'"
                                                                readonly
                                                                v-on="on"
                                                                dense
                                                                :rules="[rules.required]"
                                                                required
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="addedFilters[index].datePicker1" no-title scrollable></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        addedFilters[index].column &&
                                                            addedFilters[index].filter &&
                                                            [operatorTypes.NULL_OPERATOR, operatorTypes.NON_NULL_OPERATOR, operatorTypes.IN_OPERATOR].includes(
                                                                addedFilters[index].filter
                                                            ) === false &&
                                                            timeColumns.includes(colType(addedFilters[index].column))
                                                    "
                                                    cols="1"
                                                >
                                                    <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                v-model="addedFilters[index].timePicker1"
                                                                :label="addedFilters[index].filter === operatorTypes.BETWEEN_OPERATOR ? 'Start time' : 'time'"
                                                                readonly
                                                                v-on="on"
                                                                dense
                                                                :rules="[rules.required]"
                                                                required
                                                            ></v-text-field>
                                                        </template>
                                                        <v-time-picker
                                                            v-model="addedFilters[index].timePicker1"
                                                            use-seconds
                                                            format="24hr"
                                                            scrollable
                                                        ></v-time-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        addedFilters[index].column &&
                                                            addedFilters[index].filter &&
                                                            dateAndTimeColumns.includes(colType(addedFilters[index].column)) &&
                                                            addedFilters[index].filter === operatorTypes.BETWEEN_OPERATOR
                                                    "
                                                    cols="1"
                                                >
                                                    <div style="height: 48px" class="d-flex align-center justify-center mx-1">
                                                        <span class="text-uppercase">and</span>
                                                    </div>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        addedFilters[index].column &&
                                                            addedFilters[index].filter &&
                                                            dateColumns.includes(colType(addedFilters[index].column)) &&
                                                            addedFilters[index].filter === operatorTypes.BETWEEN_OPERATOR
                                                    "
                                                    cols="1"
                                                >
                                                    <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                v-model="addedFilters[index].datePicker2"
                                                                label="End date"
                                                                readonly
                                                                v-on="on"
                                                                dense
                                                                :rules="[rules.required]"
                                                                required
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="addedFilters[index].datePicker2" no-title scrollable></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        addedFilters[index].column &&
                                                            addedFilters[index].filter &&
                                                            timeColumns.includes(colType(addedFilters[index].column)) &&
                                                            addedFilters[index].filter === operatorTypes.BETWEEN_OPERATOR
                                                    "
                                                    class="ml-2"
                                                    cols="1"
                                                >
                                                    <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                v-model="addedFilters[index].timePicker2"
                                                                label="End time"
                                                                readonly
                                                                v-on="on"
                                                                dense
                                                                :rules="[rules.required]"
                                                                required
                                                            ></v-text-field>
                                                        </template>
                                                        <v-time-picker
                                                            use-seconds
                                                            v-model="addedFilters[index].timePicker2"
                                                            format="24hr"
                                                            scrollable
                                                        ></v-time-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-form>
                                </div>
                            </div>
                        </div>
                        <v-btn :disabled="addedFilters.length > 0 && !areFiltersValid" color="secondary" @click="step = 3">Continue</v-btn>
                        <v-btn @click="step = 1" text>Back</v-btn>
                    </v-stepper-content>
                    <v-stepper-step color="secondary" :complete="step > 3" step="3"
                        >Aggregate<small class="mt-1" v-if="step === 3"
                            >Aggregate values in selected columns, forming groups based on the values of other columns</small
                        ></v-stepper-step
                    >
                    <v-stepper-content step="3">
                        <div class="mb-12">
                            <v-btn @click="addAggregationCondition" text class="mr-3 font-weight-bold" color="primary">+ Add Aggregation Function</v-btn>
                            <div v-if="addedAggregationConditions.length">
                                <div v-for="(aggregator, index) in addedAggregationConditions" :key="index" class="d-flex align-center">
                                    <v-btn color="error" @click="removeAggregationCondition(aggregator.index)" icon><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    <v-form v-model="aggregator.valid">
                                        <v-row align="center" no-gutters>
                                            <v-col cols="3" class="mr-2">
                                                <v-select
                                                    label="Function"
                                                    :items="filteredAggregationFunctions"
                                                    v-model="aggregator.aggFunction"
                                                    required
                                                    :rules="[rules.required]"
                                                    @change="aggregator.aggregationColumns = getAggregationColumnNames(aggregator.aggFunction)"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    label="Column"
                                                    :items="aggregator.aggregationColumns"
                                                    v-model="aggregator.column"
                                                    required
                                                    :rules="[rules.required]"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="1">
                                                <div class="d-flex justify-center text-uppercase">
                                                    as
                                                </div>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="aggregator.alias"
                                                    label="Optional alias.."
                                                    :rules="[rules.aliasNameDifferentThanColumNames, rules.uniqueAggregationAliasName]"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </div>
                            </div>
                        </div>
                        <div class="mb-6">
                            <v-btn
                                @click="addAggregationFilter"
                                :disabled="!addedAggregationConditions.length || !validAggregationConditions.length || onlyAggregationWithoutGroupBy"
                                text
                                class="font-weight-bold"
                                color="primary"
                                >+ Add Aggregation Filter</v-btn
                            >
                            <div v-for="(aggregationFilter, index) in addedAggregationFilters" :key="index" class="d-flex align-center">
                                <v-btn color="error" @click="removeAggregationFilter(aggregationFilter.index)" icon><v-icon>mdi-delete-outline</v-icon></v-btn>
                                <v-form v-model="aggregationFilter.valid">
                                    <v-row align="center" no-gutters>
                                        <v-col cols="4">
                                            <v-select
                                                label="Column"
                                                :items="validAggregationConditions"
                                                v-model="aggregationFilter.column"
                                                @change="aggregationFilter.valueType = getAggregationFilterColumnType(aggregationFilter.column)"
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col class="mx-2" cols="3">
                                            <v-select
                                                label="Filter"
                                                :items="Object.keys(aggregationFilterOperators)"
                                                v-model="aggregationFilter.filter"
                                                required
                                                :rules="[rules.required]"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-text-field
                                                required
                                                v-model="aggregationFilter.value"
                                                :rules="
                                                    numericColumns.includes(aggregationFilter.valueType) || /^(COUNT|AVG)\(.*/.test(aggregationFilter.column)
                                                        ? [rules.required, rules.mustBeFloat]
                                                        : [rules.required]
                                                "
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </div>
                        </div>
                        <v-btn
                            @click="step = 4"
                            :disabled="
                                (addedAggregationConditions.length > 0 && !areAggregationConditionsValid) ||
                                    (addedAggregationFilters.length > 0 && !areAggregationFiltersValid)
                            "
                            color="secondary"
                            >Continue</v-btn
                        >
                        <v-btn @click="step = 2" text>Back</v-btn>
                    </v-stepper-content>
                    <v-stepper-step color="secondary" :complete="step > 4" step="4"
                        >Sort
                        <small class="mt-1" v-if="step === 4">Sort data in either ascending or descending according to one or more columns</small>
                    </v-stepper-step>
                    <v-stepper-content step="4">
                        <div class="mb-12">
                            <v-btn @click="addOrderingCondition" :disabled="onlyAggregationWithoutGroupBy" text class="mr-3 font-weight-bold" color="primary"
                                >+ Add Ordering Column</v-btn
                            >
                            <div v-if="addedOrderingConditions.length">
                                <div v-for="(order, index) in addedOrderingConditions" :key="index" class="d-flex align-center">
                                    <v-btn color="error" @click="removeOrderingCondition(order.index)" icon><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    <v-row align="center">
                                        <v-col cols="6">
                                            <v-form v-model="order.valid">
                                                <v-select v-model="order.column" :items="orderByColumnNames" label="Columns" required :rules="[rules.required]">
                                                    <template v-slot:prepend-item>
                                                        <v-text-field autofocus v-model="orderBySearch" class="px-4" label="Filter"></v-text-field>
                                                    </template>
                                                </v-select>
                                            </v-form>
                                        </v-col>
                                        <v-col cols="6">
                                            <div class="d-flex align-center">
                                                <v-radio-group v-model="order.order" row>
                                                    <v-radio label="Ascending" value="ASC"></v-radio>
                                                    <v-radio label="Descending" value="DESC"></v-radio>
                                                </v-radio-group>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </div>
                        <v-btn @click="step = 5" :disabled="addedOrderingConditions.length > 0 && !areOrderingConditionsValid" color="secondary"
                            >Continue</v-btn
                        >
                        <v-btn @click="step = 3" text>Back</v-btn>
                    </v-stepper-content>
                    <v-stepper-step color="secondary" :complete="step > 5" step="5"
                        >Row limit<small class="mt-1" v-if="step === 5">Specify the number of records to return</small></v-stepper-step
                    >
                    <v-stepper-content step="5">
                        <v-form class="my-3" v-model="validRowNumberForm">
                            <v-text-field
                                :disabled="onlyAggregationWithoutGroupBy"
                                outlined
                                dense
                                required
                                v-model="numberRows"
                                :rules="[rules.required, rules.maxAndIntQueryRows]"
                            ></v-text-field>
                        </v-form>
                        <v-btn @click="step = 6" :disabled="validRowNumberForm === false" color="secondary">Continue</v-btn>
                        <v-btn @click="step = 4" text>Back</v-btn>
                    </v-stepper-content>
                    <v-stepper-step color="secondary" step="6"
                        >Final query
                        <small class="mt-1" v-if="step === 6">Review the final query before submitting it</small>
                    </v-stepper-step>
                    <v-stepper-content step="6">
                        <pre style="max-width:700px" class="pb-5 pt-2" v-text="query"></pre>
                        <QuerySubmit
                            mode="PREVIEW"
                            buttonName="submit preview query"
                            :iconShaped="false"
                            @success="closeDialogAndChangeTab()"
                            :forDialog="true"
                            :query="query"
                        ></QuerySubmit>
                        <v-btn @click="step = 5" text>Back</v-btn>
                    </v-stepper-content>
                </v-stepper>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapState } from 'vuex'

const QuerySubmit = () => import('../components/TheSnapshotSubmitQuery')

export default {
    name: 'QueryDialog',
    components: { QuerySubmit },
    data: function() {
        return {
            queryDialog: false,
            step: 1,
            addedFilters: [],
            addedOrderingConditions: [],
            addedAggregationConditions: [],
            addedAggregationFilters: [],
            aggregationFilterIndex: 0,
            filterIndex: 0,
            orderingColumnIndex: 0,
            aggregationColumnIndex: 0,
            columnSearch: '',
            orderBySearch: '',
            validRowNumberForm: true,
            fetchingTableColumnsForQuery: false,
            numberRows: 10000,
            queryColumnsList: [],
            rules: {
                required: value => !!value || 'Required.',
                nonEmpty: value => value.length > 0 || 'Select at least one item.',
                aliasNameDifferentThanColumNames: value => !this.selectedColumnsNames.includes(value) || 'Cannot use selected column names.',
                uniqueAggregationAliasName: value =>
                    !value || this.aggregationConditionsAliasNames.filter(alias => value === alias).length === 1 || 'Alias name already used',
                maxAndIntQueryRows: value => (!!parseInt(value) && value >= 0 && value <= 10000000) || 'Please insert a number between 1 and 10000000 rows',
                mustBeFloat: value => Number(value) === parseFloat(value) || 'Must be a number'
            },
            selectAllColumns: false,
            aggregationFilterOperators: { 'Greater than': '>', 'Less than': '<', Equal: '=' },
            selectedTable: null,
            selectedColumns: [],
            numericColumns: ['NUMBER', 'FLOAT'],
            dateAndTimeColumns: ['DATE', 'TIME', 'TIMESTAMP_NTZ'],
            dateColumns: ['DATE', 'TIMESTAMP_NTZ'],
            timeColumns: ['TIME', 'TIMESTAMP_NTZ'],
            columnTypes: {
                STRING_COLUMN: 'VARCHAR',
                DATE_COLUMN: 'DATE',
                TIME_COLUMN: 'TIME',
                TIMESTAMP_COLUMN: 'TIMESTAMP_NTZ',
                NUMERIC_COLUMN: 'NUMBER',
                FLOAT_COLUMN: 'FLOAT'
            },
            operatorTypes: {
                EQUAL_OPERATOR: 'equal',
                NON_EQUAL_OPERATOR: 'not equal',
                LESS_THAN_OPERATOR: 'less than',
                GREATER_THAN_OPERATOR: 'greater than',
                IN_OPERATOR: 'in',
                BETWEEN_OPERATOR: 'between',
                LESS_OR_EQUAL_OPERATOR: 'less than or equal',
                GREATER_OR_EQUAL_OPERATOR: 'greater than or equal',
                NULL_OPERATOR: 'is null',
                NON_NULL_OPERATOR: 'is not null'
            },
            operatorsMappings: {
                'greater than': '>',
                'less than': '<',
                equal: '=',
                'not equal': '!=',
                in: 'in',
                between: 'between',
                'less than or equal': '<=',
                'greater than or equal': '>='
            },
            aggregationFunctions: { Average: 'AVG', Sum: 'SUM', Minimum: 'MIN', Maximum: 'MAX', Count: 'COUNT' }
        }
    },
    props: {
        tableName: String,
        tableId: Number,
        listItem: Boolean,
        setDataFromLastQuery: Boolean,
        outlinedActivationButton: { type: Boolean, default: false },
        smallActivationButton: { type: Boolean, default: true },
        buttonName: { type: String, default: 'query' }
    },
    computed: {
        ...mapState('snapshotStore', ['latestQueryBuilderQueryData']),
        allColumns() {
            if (this.$data.queryColumnsList && this.$data.queryColumnsList.length) {
                const columnsData = this.$data.queryColumnsList.map(function(column) {
                    const filteredData = {
                        colName: column.short_id,
                        type: column.coltype.includes('(') ? column.coltype.substring(0, column.coltype.indexOf('(')) : column.coltype,
                        desc: column.description ? column.description : 'No description available'
                    }
                    return filteredData
                })
                return columnsData
            }
            return []
        },
        selectedColumnsNames() {
            if (this.selectAllColumns === true && this.allColumns) {
                return this.allColumns.map(column => column.colName)
            } else if (!this.selectAllColumns) {
                return this.selectedColumns
            }
            return []
        },
        onlyAggregationWithoutGroupBy() {
            if (
                this.aggregationColumnNames.length &&
                this.selectedColumnsNames.length &&
                this.aggregationColumnNames.length === this.selectedColumnsNames.length
            ) {
                return true
            }
            return false
        },
        aggregationColumnNames() {
            if (this.addedAggregationConditions) {
                const colNames = this.addedAggregationConditions.map(condition => condition.column)
                const uniqueColNames = [...new Set(colNames)]
                return uniqueColNames
            }
            return []
        },
        validAggregationConditions() {
            if (this.addedAggregationConditions) {
                return this.addedAggregationConditions
                    .filter(condition => condition.valid)
                    .map(condition => {
                        return condition.alias ? condition.alias : `${this.aggregationFunctions[condition.aggFunction]}(${JSON.stringify(condition.column)})`
                    })
            }
            return []
        },
        aggregateConditionsColumnMappings() {
            if (this.addedAggregationConditions.length) {
                return this.addedAggregationConditions.map(condition => {
                    return {
                        colName: condition.column,
                        function: condition.alias
                            ? condition.alias
                            : `${this.aggregationFunctions[condition.aggFunction]}(${JSON.stringify(condition.column)})`,
                        aggregator: condition.aggFunction
                    }
                })
            }
            return []
        },
        orderByColumnNames() {
            const filteredColumns = this.orderBySearch
                ? this.selectedColumnsNames.filter(col => col.toLowerCase().includes(this.orderBySearch))
                : this.selectedColumnsNames
            if (this.validAggregationConditions.length > 0) {
                const columns = filteredColumns
                    .filter(column => !this.aggregationColumnNames.includes(column))
                    .concat(this.validAggregationConditions)
                    .sort()
                return columns
            }
            return filteredColumns
        },
        selectedColumnsData() {
            if (this.selectAllColumns === true && this.allColumns) {
                return this.columnData
            } else if (this.selectAllColumns === false) {
                return this.columnData.filter(column => this.selectedColumnsNames.includes(column.colName))
            }
            return []
        },
        areFiltersValid() {
            const isValid = filter => filter.valid === false
            if (this.addedFilters.length > 0 && this.addedFilters.some(isValid) === false) {
                return true
            }
            return false
        },
        areOrderingConditionsValid() {
            const isValid = condition => condition.valid === false
            if (this.addedOrderingConditions.length > 0 && this.addedOrderingConditions.some(isValid) === false) {
                return true
            }
            return false
        },
        areAggregationConditionsValid() {
            const isValid = condition => condition.valid === false
            if (this.addedAggregationConditions.length > 0 && this.addedAggregationConditions.some(isValid) === false) {
                return true
            }
            return false
        },
        areAggregationFiltersValid() {
            const isValid = condition => condition.valid === false
            if (this.addedAggregationFilters.length > 0 && this.addedAggregationFilters.some(isValid) === false) {
                return true
            }
            return false
        },
        aggregationConditionsAliasNames() {
            if (this.addedAggregationConditions.length) {
                const nonNullAliases = this.addedAggregationConditions.filter(condition => condition.alias).map(condition => condition.alias)
                return nonNullAliases
            }
            return []
        },
        filteredAggregationFunctions() {
            if (this.selectedColumnsData) {
                const selectedColumnTypes = this.selectedColumnsData.map(column => column.type)
                const availableAggregationFunctions = this.numericColumns.some(type => selectedColumnTypes.includes(type))
                    ? ['Average', 'Sum', 'Count', 'Minimum', 'Maximum']
                    : ['Count', 'Minimum', 'Maximum']
                return availableAggregationFunctions
            }
            return []
        },
        query() {
            const groupByFunctionStatement = this.addedAggregationConditions.length
                ? this.addedAggregationConditions
                      .map(condition => {
                          const aggregationFunctionString = `${this.aggregationFunctions[condition.aggFunction]}(${JSON.stringify(condition.column)})`
                          return condition.alias ? `${aggregationFunctionString} AS ${JSON.stringify(condition.alias)}` : aggregationFunctionString
                      })
                      .join(',\n ')
                : ''
            const fromStatement = `\nFROM\n ${JSON.stringify(this.selectedTable)}`
            const selectStatement =
                this.selectAllColumns && !this.addedAggregationConditions.length
                    ? 'SELECT *' + fromStatement
                    : !this.selectAllColumns && !this.addedAggregationConditions.length
                    ? `SELECT\n ${this.selectedColumnsNames.map(col => `${JSON.stringify(col)}`).join(',\n ')}` + fromStatement
                    : this.onlyAggregationWithoutGroupBy
                    ? `SELECT\n ${groupByFunctionStatement}` + fromStatement
                    : `SELECT\n ${this.selectedColumnsNames
                          .filter(col => !this.aggregationColumnNames.includes(col))
                          .map(col => `${JSON.stringify(col)}`)
                          .join(',\n ')},\n ${groupByFunctionStatement}` + fromStatement
            const orderByStatement =
                this.addedOrderingConditions.length > 0 && this.areOrderingConditionsValid
                    ? `\nORDER BY\n ${this.addedOrderingConditions
                          .map(condition => {
                              return `${/^(COUNT|AVG|SUM|MIN|MAX)\(.*/.test(condition.column) ? condition.column : JSON.stringify(condition.column)} ${
                                  condition.order
                              }`
                          })
                          .join(',\n ')}`
                    : ''
            const groupByFilterStatement =
                this.addedAggregationFilters.length > 0 && this.areAggregationFiltersValid
                    ? `\nHAVING\n ${this.addedAggregationFilters
                          .map(filter => {
                              const aggregationColumnName = this.aggregateConditionsColumnMappings.find(condition => condition.function === filter.column)
                              if (aggregationColumnName && this.selectedColumnsData.length) {
                                  const aggregationColumnType = this.selectedColumnsData.find(col => col.colName === aggregationColumnName.colName).type
                                  return `${/^(COUNT|AVG|SUM|MIN|MAX)\(.*/.test(filter.column) ? filter.column : JSON.stringify(filter.column)}  ${
                                      this.aggregationFilterOperators[filter.filter]
                                  }  ${
                                      this.numericColumns.includes(aggregationColumnType) || aggregationColumnName.aggregator === 'Count'
                                          ? filter.value
                                          : "'" + filter.value + "'"
                                  }`
                              }
                          })
                          .join('\n and ')}`
                    : ''
            const groupByColumnsStatement =
                this.addedAggregationConditions.length > 0 && !this.onlyAggregationWithoutGroupBy
                    ? `\nGROUP BY\n ${this.selectedColumnsNames
                          .filter(col => !this.aggregationColumnNames.includes(col))
                          .map(col => `${JSON.stringify(col)}`)
                          .join(',\n ')}${groupByFilterStatement}`
                    : ''
            const whereStatement =
                this.addedFilters.length > 0
                    ? '\nWHERE\n ' +
                      this.$data.addedFilters
                          .map(filter => {
                              const dateAndNumericFilters = [
                                  this.operatorTypes.EQUAL_OPERATOR,
                                  this.operatorTypes.NON_EQUAL_OPERATOR,
                                  this.operatorTypes.GREATER_THAN_OPERATOR,
                                  this.operatorTypes.LESS_THAN_OPERATOR,
                                  this.operatorTypes.GREATER_OR_EQUAL_OPERATOR,
                                  this.operatorTypes.LESS_OR_EQUAL_OPERATOR
                              ]
                              if (dateAndNumericFilters.includes(filter.filter) && this.numericColumns.includes(this.colType(filter.column))) {
                                  return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} ${filter.inputField1}`
                              } else if (
                                  [this.operatorTypes.EQUAL_OPERATOR, this.operatorTypes.NON_EQUAL_OPERATOR].includes(filter.filter) &&
                                  filter.inputField1 &&
                                  this.colType(filter.column) === this.columnTypes.STRING_COLUMN
                              ) {
                                  return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} '${filter.inputField1.replace(/'/g, "''")}'`
                              } else if (dateAndNumericFilters.includes(filter.filter) && this.colType(filter.column) === this.columnTypes.DATE_COLUMN) {
                                  return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} '${filter.datePicker1}'`
                              } else if (
                                  filter.filter === this.operatorTypes.BETWEEN_OPERATOR &&
                                  this.colType(filter.column) === this.columnTypes.DATE_COLUMN
                              ) {
                                  return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} '${filter.datePicker1}' and '${
                                      filter.datePicker2
                                  }'`
                              } else if (dateAndNumericFilters.includes(filter.filter) && this.colType(filter.column) === this.columnTypes.TIME_COLUMN) {
                                  return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} '${filter.timePicker1}'`
                              } else if (
                                  filter.filter === this.operatorTypes.BETWEEN_OPERATOR &&
                                  this.colType(filter.column) === this.columnTypes.TIME_COLUMN
                              ) {
                                  return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} '${filter.timePicker1}' and '${
                                      filter.timePicker2
                                  }'`
                              } else if (dateAndNumericFilters.includes(filter.filter) && this.colType(filter.column) === this.columnTypes.TIMESTAMP_COLUMN) {
                                  return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} '${filter.datePicker1} ${
                                      filter.timePicker1
                                  }'`
                              } else if (
                                  filter.filter === this.operatorTypes.BETWEEN_OPERATOR &&
                                  this.colType(filter.column) === this.columnTypes.TIMESTAMP_COLUMN
                              ) {
                                  return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} '${filter.datePicker1} ${
                                      filter.timePicker1
                                  }' and '${filter.datePicker2} ${filter.timePicker2}'`
                              } else if (filter.filter === this.operatorTypes.BETWEEN_OPERATOR && this.numericColumns.includes(this.colType(filter.column))) {
                                  return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} ${filter.inputField1} and ${
                                      filter.inputField2
                                  }`
                              } else if (filter.filter === this.operatorTypes.IN_OPERATOR && filter.file) {
                                  return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} (${filter.fileContent})`
                              } else if (
                                  filter.filter === this.operatorTypes.IN_OPERATOR &&
                                  filter.inputField1 &&
                                  !filter.file &&
                                  this.numericColumns.includes(this.colType(filter.column))
                              ) {
                                  return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} (${filter.inputField1})`
                              } else if (
                                  filter.filter === this.operatorTypes.IN_OPERATOR &&
                                  filter.inputField1 &&
                                  !filter.file &&
                                  this.numericColumns.includes(this.colType(filter.column)) === false
                              ) {
                                  return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} (${filter.inputField1
                                      .split(',')
                                      .map(val => `'${val.replace(/'/g, "''")}'`)
                                      .join(', ')})`
                              } else if ([this.operatorTypes.NULL_OPERATOR, this.operatorTypes.NON_NULL_OPERATOR].includes(filter.filter)) {
                                  return `${JSON.stringify(filter.column)} ${filter.filter}`
                              }
                          })
                          .join('\n AND ')
                    : ''
            const limitStatement = this.onlyAggregationWithoutGroupBy ? '' : `\nLIMIT ${this.$data.numberRows}`
            return selectStatement + whereStatement + groupByColumnsStatement + orderByStatement + limitStatement
        },
        columnData() {
            if (this.$data.columnSearch && this.allColumns) {
                return this.allColumns.filter(
                    column =>
                        column.colName.toLowerCase().includes(this.$data.columnSearch.toLowerCase()) ||
                        column.desc.toLowerCase().includes(this.$data.columnSearch.toLowerCase())
                )
            }
            return this.allColumns
        }
    },
    methods: {
        getAggregationColumnNames(aggFunction) {
            if (['Average', 'Sum'].includes(aggFunction)) {
                const filteredColumns = this.selectedColumnsData.filter(column => this.numericColumns.includes(column.type))
                return filteredColumns ? filteredColumns.map(column => column.colName) : []
            } else {
                return this.selectedColumnsNames
            }
        },
        getAggregationFilterColumnType(aggFunction) {
            const aggregationCondition = this.aggregateConditionsColumnMappings.find(condition => condition.function === aggFunction)
            const columnType = aggregationCondition ? this.selectedColumnsData.find(col => col.colName === aggregationCondition.colName).type : null
            return columnType
        },
        closeDialogAndChangeTab() {
            const queryData = {
                table: this.$data.selectedTable,
                queryColumnsList: this.queryColumnsList,
                columns: this.selectedColumnsNames.length === this.allColumns.length ? 'all' : this.selectedColumnsNames,
                tableId: this.$props.tableId ? this.$props.tableId : this.latestQueryBuilderQueryData.tableId ? this.latestQueryBuilderQueryData.tableId : null,
                whereFilters: this.addedFilters,
                aggregators: this.addedAggregationConditions,
                aggregationFilters: this.addedAggregationFilters,
                orderByConditions: this.addedOrderingConditions,
                whereFiltersIndex: this.filterIndex + 1,
                aggregationConditionIndex: this.aggregationColumnIndex + 1,
                aggregationFilterIndex: this.aggregationFilterIndex + 1,
                orderByIndex: this.orderingColumnIndex + 1,
                rowLimit: this.$data.numberRows,
                query: this.query
            }
            this.$store.dispatch('snapshotStore/setLatestQueryBuilderQueryData', queryData)
            this.$data.queryDialog = false
            this.$emit('querySubmitSuccess')
        },
        fetchColumnsForQuery: function(tid) {
            if (tid) {
                this.$data.fetchingTableColumnsForQuery = true
                this.$axios
                    .get(`/tables/${tid}/columns`)
                    .then(response => {
                        this.$data.queryColumnsList = response.data
                    })
                    .catch(() => {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Failed to fetch table column data, try refreshing the page.',
                            snackBarIcon: 'error'
                        })
                    })
                    .finally(() => {
                        this.$data.fetchingTableColumnsForQuery = false
                    })
            }
        },
        uploadFile(evt, filterIdx) {
            if (evt !== undefined && evt !== null) {
                var reader = new FileReader()
                reader.readAsText(evt)
                reader.onload = () => {
                    this.setFileContentForFilter(reader.result, filterIdx)
                }
            } else {
                this.$data.addedFilters[filterIdx].fileContent = null
                this.$data.addedFilters[filterIdx].file = null
                this.$data.addedFilters[filterIdx].inputField1 = null
            }
        },
        setFileContentForFilter(fileData, filterIdx) {
            const filter = this.$data.addedFilters[filterIdx]
            var formatterData
            if (filter && this.numericColumns.includes(this.colType(filter.column))) {
                formatterData = fileData.split('\n').map(dataPoint => parseInt(dataPoint))
            } else {
                formatterData = fileData.split('\n').map(dataPoint => `'${dataPoint}'`)
            }
            this.$data.addedFilters[filterIdx].fileContent = formatterData
            this.$data.addedFilters[filterIdx].inputField1 = formatterData
        },
        addFilter() {
            const id = this.$data.filterIndex
            this.$data.addedFilters.push({
                index: id,
                column: null,
                filter: null,
                inputField1: null,
                inputField2: null,
                datePicker1: null,
                datePicker2: null,
                timePicker1: null,
                timePicker2: null,
                file: null,
                fileContent: null,
                valid: false
            })
            this.$data.filterIndex += 1
        },
        addOrderingCondition() {
            const id = this.$data.orderingColumnIndex
            const defaultColumn = this.orderByColumnNames[0]
            this.$data.addedOrderingConditions.push({
                index: id,
                column: defaultColumn,
                order: 'ASC',
                valid: false
            })
            this.$data.orderingColumnIndex += 1
        },
        addAggregationCondition() {
            const id = this.$data.aggregationColumnIndex
            const column = this.aggregationColumnNames.length ? this.aggregationColumnNames[0] : this.selectedColumnsNames[0]
            this.$data.addedAggregationConditions.push({
                index: id,
                aggFunction: 'Count',
                column: column,
                alias: '',
                aggregationColumns: this.selectedColumnsNames,
                valid: false
            })
            this.$data.aggregationColumnIndex += 1
        },
        addAggregationFilter() {
            const id = this.$data.aggregationFilterIndex
            const conditionColumnType = this.getAggregationFilterColumnType(this.validAggregationConditions[0])
            this.$data.addedAggregationFilters.push({
                index: id,
                filter: 'Equal',
                column: this.validAggregationConditions[0],
                value: null,
                valueType: conditionColumnType,
                valid: false
            })
            this.$data.aggregationFilterIndex += 1
        },
        removeFilter(filterIndex) {
            this.$data.addedFilters = this.$data.addedFilters.filter(item => item.index !== filterIndex)
        },
        removeOrderingCondition(orderIndex) {
            this.$data.addedOrderingConditions = this.$data.addedOrderingConditions.filter(item => item.index !== orderIndex)
        },
        removeAggregationCondition(aggregatorIndex) {
            this.$data.addedAggregationConditions = this.$data.addedAggregationConditions.filter(item => item.index !== aggregatorIndex)
        },
        removeAggregationFilter(filterIndex) {
            this.$data.addedAggregationFilters = this.$data.addedAggregationFilters.filter(item => item.index !== filterIndex)
        },
        clearFilterDataUponColumnChange(filterArrayIndex) {
            this.$data.addedFilters[filterArrayIndex].filter = null
            this.$data.addedFilters[filterArrayIndex].inputField1 = null
            this.$data.addedFilters[filterArrayIndex].inputField2 = null
            this.$data.addedFilters[filterArrayIndex].datePicker1 = null
            this.$data.addedFilters[filterArrayIndex].datePicker2 = null
            this.$data.addedFilters[filterArrayIndex].timePicker1 = null
            this.$data.addedFilters[filterArrayIndex].timePicker2 = null
            this.$data.addedFilters[filterArrayIndex].fileContent = null
        },
        colType(col) {
            if (col && this.allColumns) {
                const colData = this.allColumns.find(item => item.colName === col)
                if (colData) {
                    return colData.type
                } else {
                    return null
                }
            }
        },
        columnFilters(col) {
            if (col && this.allColumns.length > 0) {
                const colData = this.allColumns.find(item => item.colName === col)
                if ((colData && this.dateAndTimeColumns.includes(colData.type)) || this.numericColumns.includes(colData.type)) {
                    return Object.values(this.operatorTypes)
                } else if (colData) {
                    return [
                        this.operatorTypes.EQUAL_OPERATOR,
                        this.operatorTypes.NON_EQUAL_OPERATOR,
                        this.operatorTypes.IN_OPERATOR,
                        this.operatorTypes.NULL_OPERATOR,
                        this.operatorTypes.NON_NULL_OPERATOR
                    ]
                }
            } else {
                return []
            }
        }
    },
    mounted() {
        this.$data.selectedTable = this.$props.tableName
        if (this.$props.setDataFromLastQuery === true && this.latestQueryBuilderQueryData) {
            this.queryColumnsList = this.latestQueryBuilderQueryData.queryColumnsList
            this.selectedTable = this.latestQueryBuilderQueryData.table
            this.numberRows = this.latestQueryBuilderQueryData.rowLimit
            this.addedFilters = this.latestQueryBuilderQueryData.whereFilters
            this.addedAggregationConditions = this.latestQueryBuilderQueryData.aggregators
            this.addedAggregationFilters = this.latestQueryBuilderQueryData.aggregationFilters
            this.addedOrderingConditions = this.latestQueryBuilderQueryData.orderByConditions
            this.filterIndex = this.latestQueryBuilderQueryData.whereFiltersIndex
            this.aggregationColumnIndex = this.latestQueryBuilderQueryData.aggregationConditionIndex
            this.aggregationFilterIndex = this.latestQueryBuilderQueryData.aggregationFilterIndex
            this.orderingColumnIndex = this.latestQueryBuilderQueryData.orderingColumnIndex
            if (this.latestQueryBuilderQueryData.columns === 'all') {
                this.$data.selectAllColumns = true
            } else {
                this.$data.selectedColumns = this.latestQueryBuilderQueryData.columns
            }
        }
    },
    watch: {
        selectAllColumns: function(nextVal) {
            this.selectedColumns = nextVal ? this.selectedColumnsNames : []
        },
        selectedColumns: function(nextVal) {
            this.addedAggregationConditions =
                nextVal.length < 2 && !this.selectAllColumns
                    ? []
                    : this.addedAggregationConditions.length
                    ? this.addedAggregationConditions.filter(condition => nextVal.includes(condition.column))
                    : []
            this.addedFilters =
                !nextVal.length && !this.selectAllColumns
                    ? []
                    : this.addedFilters.length
                    ? this.addedFilters.filter(filter => nextVal.includes(filter.column))
                    : []
        },
        orderByColumnNames: function(nextVal) {
            this.addedOrderingConditions = !nextVal.length ? [] : this.addedOrderingConditions.filter(condition => nextVal.includes(condition.column))
        },
        queryDialog: function(nextVal, preVal) {
            if (preVal === true && nextVal === false) {
                this.$data.step = 1
                this.$emit('dialogClosed')
            } else if (preVal === false && nextVal === true) {
                this.$emit('dialogOpened')
            }
        },
        allColumns: function(nextVal) {
            if (nextVal && nextVal.length && this.$data.selectAllColumns) {
                this.$data.selectedColumns = nextVal.map(column => column.colName)
            }
        },
        addedAggregationConditions: function(nextVal) {
            this.$data.addedAggregationFilters = this.$data.addedAggregationFilters.filter(filter => this.validAggregationConditions.includes(filter.column))
        },
        validAggregationConditions: function(nextVal) {
            this.addedAggregationFilters = this.addedAggregationFilters.filter(condition => nextVal.includes(condition.column))
        },
        onlyAggregationWithoutGroupBy(nextVal) {
            if (nextVal) {
                this.addedAggregationFilters = []
                this.addedOrderingConditions = []
            }
        }
    }
}
</script>
